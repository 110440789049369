    /* Container for the entire content */
    .services-container {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    /* Wrapper for the services tabs and details */
    .services-wrapper {
        display: flex;
        max-width: 1200px; /* Limit width to ensure space on sides */
        width: 100%;
        background-color: #f8f9fa; /* Background color for the wrapper */
        border-radius: 10px;
        overflow: auto; /* Ensure content doesn't overflow */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for depth */
    }

    /* Left panel for tabs */
    .tabs {
        width: 21%; /* Adjust width for tabs */
        background-color: white; /* Dark background for tabs */
        color: white; /* Text color for tabs */
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center; /* Center align content */
    }

    .tab {
        position: relative;
        width: 100%; /* Adjust width for tabs */
        padding: 10px 20px;
        cursor: pointer;
        margin-bottom: 10px;
        border-radius: 5px;
        transition: background-color 0.3s ease;
    }

    .tab-title{
        font-size: 13px;
    }

    .tab.active {
        background-color: #aa9166; /* Active tab color */
        color: black;
    }

    /* Right panel for service details and new section */
    .content-wrapper {
        width: 79%;
        display: flex;
    }

    .details {
        width: 100%; /* Adjust width for details section */
        /* padding: 0 40px 40px; */
    }

    .icon {
        font-size: 20px; /* Adjust icon size as needed */
        color: black; /* Set icon color to black */
        background-color: white; /* Set background color for the rounded icon */
        border-radius: 50%; /* Make the icon rounded */
        padding: 8px; /* Adjust padding to create a circle around the icon */
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    /* .tab-content {
        display: flex;
        align-items: center;
        position: relative; /* Needed for the "New" tag to position correctly 
    } */

    .new-tag {
        position: absolute;
        top: 10px; /* Position it above the text */
        right: 25px;
        background-color: #730202; /* Background color for contrast */
        color: white; /* Text color to match the active tab color */
        padding: 2px 6px; /* Smaller padding for a smaller tag */
        border-radius: 12px; /* Rounded corners for the "New" tag */
        font-size: 10px; /* Smaller font size */
        font-weight: bold; /* Make the text bold */
        transform: translateX(100%); /* Position it slightly to the right of the text */
        white-space: nowrap; /* Prevent text wrapping */
        animation: pulse 1.5s infinite; /* Animation for pulsing effect */
    }

    /* Pulse animation */
    @keyframes pulse {
        0% {
            transform: translateX(100%) scale(1);
            opacity: 1;
        }
        50% {
            transform: translateX(100%) scale(1.1);
            opacity: 0.8;
        }
        100% {
            transform: translateX(100%) scale(1);
            opacity: 1;
        }
    }

    

    .whats-new {
        width: 30%; /* Adjust width for the new section */
        padding: 20px;
        background-color: #f0f0f0; /* Background color for the new section */
        border-left: 1px solid #ddd; /* Optional: Add a border to separate sections */
        overflow: hidden;
        position: relative; /* Required for vertical scrolling effect */
        height: 100%; /* Ensure it takes full height of content-wrapper */
    }

    .whats-new h3 {
        color: black;   
    }

    .whats-new ul li{
            padding-top: 5%;
            font-family: 'Roboto', sans-serif;
        }

    .marquee-container {
        width: 100%;
        height: 100%; /* Ensure container takes full height */
        overflow: hidden;
        position: relative; /* Required for vertical scrolling effect */
    }

    .marquee-content {
        display: flex;
        flex-direction: column; /* Align items vertically */
        position: absolute; /* Required for positioning animation */
        top: 0;; /* Start position at the bottom of the container */
        animation: marquee-vertical 20s linear infinite;
    }

    .marquee-content p {
        margin-bottom: 20px; /* Space between repeated text */
    }

    .marquee-content ul li{
        font-size: 15px;
        line-height: 17px;
    }

    .new-tag-in-content {
        position: absolute;
        top: -10px; /* Position above the text */
        right: 0;
        background-color: red; /* Background color for contrast */
        color: white; /* Text color to match the active tab color */
        padding: 2px 6px; /* Smaller padding for a smaller tag */
        border-radius: 12px; /* Rounded corners for the "New" tag */
        font-size: 7px; /* Smaller font size */
        font-weight: bold; /* Make the text bold */
        white-space: nowrap; /* Prevent text wrapping */
        animation: pulse 1.5s infinite; /* Animation for pulsing effect */
    }

    /* Pulse animation */
    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        50% {
            transform: scale(1.1);
            opacity: 0.8;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes marquee-vertical {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(-100%);
        }
    }

    /* service-details */
    .service-details {
        background-color: white; /* White background for details */
        /* padding: 20px; */
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for details */
    }

    /* service-details */
    .service-details p,
    .service-details h3 {
        color: black;
    }


    .btn {
        background-color: #98142c; /* Primary button color */
        color: #fff; /* Text color for button */
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        margin-top: 10px;
    }

    .btn:hover {
        background-color: black; /* Hover color for button */
        color: white;
    }

    .divider {
        border-top: 1px solid #98142c;
        margin: 5px 0; /* Adjust margin as needed */
    }



    /* Responsive adjustments for mobile devices */
    @media (max-width:  767.98px) {
        .services-wrapper {
            flex-direction: column; /* Stack tabs and content-wrapper vertically */
            width: 100%; /* Full width on mobile */
        }

        .tabs {
            width: 100%; /* Full width for tabs on mobile */
        }
        

        .content-wrapper {
            flex-direction: column; /* Stack details and what's new vertically */
            display: flex;
            width: 100%; /* Full width for content-wrapper on mobile */
            padding: 0;
        }

        .details{
            width: 100%; /* Full width for both sections on mobile */
            border-left: none; /* Remove border-left on mobile */
            border-top: 1px solid #ddd; /* Add top border for separation */
            padding: 0;
        }
        .service-details h3{
            width: 100%;
            padding: 0;
            font-size: 24px;
        }
        

        .whats-new {
            margin-top: 50px;
            border-top: 1px solid #ddd; /* Border-top to separate sections */
            width: 100%;
            height: 80vh;
            padding: 10%;
        }
        .whats-new ul li{
            padding-top: 5%;
            font-family: 'Roboto', sans-serif;
        }
    }


/*******************************/
/******* Disclaimer CSS ********/
/*******************************/
/* Improved CSS for the Disclaimer Section */

.disclaimer-section {
    background-color: #333; /* Clean black background */
    padding: 08px 20px; /* Balanced padding */
    color: white; /* White text for good contrast */
    font-family: 'Arial', sans-serif; /* Professional, clean font */
    font-size: 18px; /* Medium font size for readability */
    line-height: 1.6; /* Good line height for easy reading */
    text-align: center; /* Center align text */
}

.disclaimer-section p {
    margin: 0;
    color:#fff /* Remove extra margin around the paragraph */
}

strong {
    font-weight: bold; /* Emphasize important parts with bold text */
}



/* *******************************/
/******* Popup CSS ********/
/*******************************/
/* Popup.css */
.popup-overlay {
    position: fixed; /*Ensure it's fixed relative to the viewport*/
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High z-index to ensure it sits on top of other content */
    overflow: hidden; /* Ensure no scroll on overlay itself */
}

.popup {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    z-index: 1001; /* Ensure the popup itself is above the overlay */
}

.popup-title {
    color: #98142c;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
}

.popup-body {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
}

.popup-steps {
    margin-bottom: 20px;
}

.popup-steps-title {
    font-size: 15px;
    color: #98142c;
    margin-bottom: 10px;
}

.popup-steps-list {
    list-style-type: decimal;
    padding-left: 20px;
}

.popup-step {
    font-size: 14px;
    color: #555;
    margin-bottom: 10px;
}

.popup-footer {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.popup-contact {
    font-size: 14px;
    color: #98142c;
    text-align: center;
}

.popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.popup-button {
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 13px;
    flex: 1;
    margin: 0 5px;
}

.close-button {
    background-color: #98142c;
    color: white;
}

.close-button:hover {
    background-color: #730f22; /* Darker shade for hover effect */
}

.connect-button {
    background-color: black;
    color: white;
}

.connect-button:hover {
    background-color: #333; /* Darker shade for hover effect */
}

