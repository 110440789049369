/* Container wrapping both the form and the sidebar */
.centralized-grievances-wrapper {
    display: flex; /* Flexbox for side-by-side layout */
    gap: 20px; /* Space between the form and the sidebar */
    max-width: 1200px; /* Max width for the entire layout */
    margin: 0 auto; /* Centering the container */
    padding: 20px;
    width: 100%;
}

/* Form container styling */
.centralized-grievances-container {
    flex: 3; /* Form takes up more space */
    background-color: #fff; /* White background for the form */
    border: 1px solid #ddd; /* Light border around the form */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.grievance-form {
    margin-bottom: 30px;
}

@media print {
    .sidebar {
        display: none; /* Hide the non-printable section */
    }
    /* You can add more styles to adjust the appearance */
}

h1 {
    color: #333;
    font-size: 35px;
    margin-bottom: 10px;
    text-align: center;
}

p {
    color: #666;
    line-height: 1.6;
}

ul {
    list-style-type: disc;
    color: #666;
    padding-left: 20px;
}

li {
    margin-bottom: 5px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
input[type="number"],
textarea,
select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
}

button {
    background-color: #aa9166;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

button:hover {
    background-color: #8d6e63;
}

/* "What's New" Section Styles */
/* Ensure the sidebar has the right layout and spacing */
.sidebar {
    flex: 1; /* Sidebar takes up less space compared to the form */
    background-color: #f9f9f9; /* Light background for the sidebar */
    border: 1px solid #ddd; /* Light border around the sidebar */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    height: 100vh; /* Full height for vertical scrolling effect */
    overflow: hidden; /* Hide overflow to ensure clean scrolling */
}

/* Specific styling for the sidebar's "What's New" section */
.sidebar .whats-new {
    margin-top: 0; /* Remove margin-top for the sidebar */
    width: 100%;
    height: 100%; /* Full height for vertical scrolling */
    overflow: hidden; /* Hide overflow for smooth scrolling effect */
}

.sidebar .whats-new h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
}

.sidebar .marquee-container {
    overflow: hidden;
    height: 100%; /* Full height to make sure content scrolls vertically */
    position: relative; /* Position relative for absolute children */
}

.sidebar .marquee-content {
    display: block; /* Block display for vertical scrolling */
    position: absolute; /* Absolute positioning for smooth vertical scroll */
    width: 100%; /* Full width */
    animation: marquee-vertical 15s linear infinite; /* Apply vertical scrolling animation */
}

.sidebar .marquee-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    display: flex;
    flex-direction: column; /* Vertical list direction */
}

.sidebar .marquee-content li {
    padding: 10px 0; /* Vertical padding for list items */
    color: #555;
    font-size: 16px;
}

.sidebar .new-tag-in-content {
    color: #e63946;
    font-weight: bold;
    margin-left: 10px;
    color: white;
}

@keyframes marquee-vertical {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(-100%);
    }
}

@media (max-width: 768px) {
    .centralized-grievances-wrapper {
        flex-direction: column; /* Stack the form and sidebar vertically on smaller screens */
    }

    .centralized-grievances-container,
    .sidebar {
        flex: none; /* Remove flex-grow behavior */
        width: 100%; /* Full width for both elements */
        margin-bottom: 20px; /* Add space below each element */
    }

    .sidebar {
        height: auto; /* Remove fixed height */
        height: 60vh;
    }

    .sidebar .whats-new h3 {
        font-size: 35px; /* Slightly smaller font size */
    }

    .sidebar .marquee-content li {
        font-size: 20px; /* Slightly smaller font size for mobile */
    }
}

@media (max-width: 480px) {
    .centralized-grievances-container,
    .sidebar {
        padding: 10px; /* Reduced padding for smaller screens */
    }
}
