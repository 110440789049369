.pops-form-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Slightly darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears on top */
}

.pops-form {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    width: 60%; /* 60% width for both mobile and desktop */
    max-width: 600px; /* Optional max width for larger screens */
    box-sizing: border-box; /* Include padding in width calculation */
}

.close-btn {
    position: absolute;
    background-color: white;
    top: 0px;
    left: 69%;
    height: 8%;
    font-size: 24px;
    color: #333;
    cursor: pointer;
}

.close-btn:hover {
    color: #333;
    background-color: white;
    border: none;
}

.pops-form h2 {
    margin: 0 0 10px;
}

.pops-form form {
    display: flex;
    flex-direction: column;
}

.pops-form input,
.pops-form select,
.pops-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.pops-form textarea {
    resize: none; /* Prevent resizing */
    height: 100px; /* Set a fixed height */
}

.pops-form button {
    padding: 10px;
    background-color: #98142c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.pops-form button:hover {
    background-color: #98143f;
}


.success-message {
    color: green; /* Or any color you prefer */
    margin-bottom: 10px; /* Space between message and form */
    font-weight: bold; /* Makes the message stand out */
  }

/* Mobile Styles */
@media (max-width: 600px) {
    .pops-form {
        width: 90%; /* 90% width for mobile */
        padding: 15px; /* Less padding on mobile */
    }

    .close-btn {
        font-size: 20px; /* Smaller close button on mobile */
        top: 47px; /* Adjust position */
        left: 81%; /* Adjust position for better visibility */
    }

    .pops-form h2 {
        font-size: 18px; /* Smaller heading */
    }

    .pops-form input,
    .pops-form select,
    .pops-form textarea {
        font-size: 14px; /* Smaller font size for inputs */
    }

    .pops-form button {
        font-size: 16px; /* Adjust button font size */
    }
}
